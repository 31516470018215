/**
 * user模块接口列表
 */
import request from '@/api/myaxios'; // 导入http中创建的axios实例

const user = {
    // 用户查询
    queryUserPage(data) {
        return request({
            url: '/api/user/queryUser',
            method: 'post',
            data: data
        });
    },
    // 用户登录
    userLogin(data) {
        return request({
            url: '/api/login',
            method: 'post',
            data: data,
            hideLoading: true
        });
    },
    // 获取用户拥有菜单
    getUserMenus(userId) {
        return request({
            url: '/api/getUserMenus/'+userId,
            method: 'get'
        });
    },
    // 更新个人信息
    updateUserInfo(data) {
        return request({
            url: '/api/user/updateUser',
            method: 'post',
            data: data
        });
    },
    // 删除用户
    delUser(id) {
        return request({
            url: '/api/user/delUser/'+id,
            method: 'get'
        });
    },
    // 新增用户
    addUser(data) {
        return request({
            url: '/api/user/addUser',
            method: 'post',
            data: data
        });
    },
    // 编辑用户
    editUser(data) {
        return request({
            url: '/api/user/editUser',
            method: 'post',
            data: data
        });
    },
    queryUserRole(userId){
        return request({
            url: '/api/user/queryUserRole/'+userId,
            method: 'get',
        });
    },

    // 获取验证码
    getCodeImg(type) {
        return request({
            url: '/api/captchaImage/' + type,
            method: 'get',
            hideLoading: true
        });
    },
    // 用户退出登录
    logout() {
        return request({
            url: '/api/logout',
            method: 'get',
            hideLoading: true
        });
    },
}

export default user;