<template>
  <div class="title">
    <div class="left-logo">
      <img :src="logoPath"/>
<!--      <span>{{ this.$store.state.unitInfo.sysName }}</span>-->
    </div>
  </div>
  <div>
    <el-menu router
             unique-opened
             active-text-color="#409eff"
             background-color="#001529"
             text-color="#fff">
      <el-sub-menu v-for="(item, index) in adminMenus" :key="item.name" :index="index+''">
        <template #title>
          <i :class="item.icon"></i><span>{{ item.name }}</span>
        </template>
        <template v-for="children in item.children" :key="children.id">
          <el-menu-item :index="children.path" @click="selectMenu(item.name,children.name)"><i
              :class="children.icon"></i>{{ children.name }}
          </el-menu-item>
        </template>
      </el-sub-menu>
    </el-menu>
  </div>

  <div class="version">版本：{{uiVersion}}</div>

</template>

<script>
import cookie from "@/utils/cookie";
import {handleTree} from "@/utils/treeUtil";
import userApi from "@/api/system/user";
import packageJson from '@/../package.json'
import variableApi from "@/api/system/variable";
import config from "@/api/config";

export default {
  name: "Left",
  data() {
    return {
      adminMenus: [
        /*{
          name: '系统管理',
          icon: 'el-icon-setting',
          children: [
            {
              path: '/system/usermanage',
              name: '用户管理'
            },
            {
              path: '/system/deptManage',
              name: '部门管理'
            },
            {
              path: '/system/roleManage',
              name: '角色管理'
            },
            {
              path: '/system/menuManage',
              name: '菜单管理'
            },
            {
              path: '/system/logManage',
              name: '日志查询'
            }
          ]
        },
        {
          name: '信息管理',
          icon: 'el-icon-menu',
          children: [
            {
              path: '/info/contentList',
              name: '内容管理',
            },
            {
              path: '/info/adList',
              name: '广告管理',
            },
            {
              path: '/info/ancestralHomeList',
              name: '祖籍信息',
            },
            {
              path: '/info/expatriateList',
              name: '侨民档案',
            },
            {
              path: '/info/liveLinkList',
              name: '直播间信息',
            }
          ]
        },*/
      ],
      uiVersion:packageJson.version,
      logoPath:'',
    }
  },
  methods: {
    selectMenu(parentName, name) {
      this.$emit('selectMenu', parentName, name);// 调用富组件方法，更新顶部导航
    }
  },
  mounted() {
    this.$router.push({path: '/admin/welcome'});
    // 获取cookie用户拥有的菜单
    /*var userMenus=JSON.parse(cookie.getCookie("userMenus"));
    this.adminMenus=handleTree(userMenus,'name');*/
    // console.log('userMenus',this.adminMenus);
    // var user=JSON.parse(cookie.getCookie("user"));
    var user = this.$store.state.user;
    if (user.id == undefined) {
      // 跳转到登录
      cookie.delCookie("token");
      cookie.delCookie("user");
      this.$router.push({path: '/admin/login'});
    } else {
      userApi.getUserMenus(user.id).then(response => {
        let menus = response.data.data;
        this.$store.commit('setUserMenus', menus);// 放到全局变量中
        this.adminMenus = handleTree(menus, 'name');
        console.log('adminMenus', this.adminMenus)
      });
      //获取系统变量-logo
      variableApi.queryVarNumVariable({varNum: "logo"}).then(res => {
        if (res.data.code == 1) {
          this.logoPath = config.server + res.data.data.logo;
        }
      })
    }

  }
}
</script>

<style scoped>
.title {
  height: 40px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  border-bottom: #409eff solid 1px;
}

.title span {
  color: #409eff;
}

.left-logo {
  vertical-align: middle;
  display: inline;
}

.left-logo img {
  vertical-align: middle;
  /*margin-right: 5px;*/
  height: 30px;
}
.left-logo span {
  vertical-align: middle;
  color: #409eff;
}

.el-menu {
  /*--el-menu-background-color: #001529;*/
  /*--el-menu-text-color: #fff;*/
  /*--el-menu-hover-background-color: #001529;*/
  border-right: none;
}

.el-menu-item {
  color: white;
  background-color: #000;
  padding-left: 50px !important;
}

.el-menu-item:hover {
  background-color: #409eff;
}

.el-menu-item.is-active {
  color: #fff;
  background-color: #409eff;
}
.version{
  width: 200px;
  text-align: center;
  position: fixed;
  bottom: 5px;
  font-size: 12px;
  color: #FFFFFF;
}
</style>