<template>
  <el-container class="index">
    <!--左侧-->
    <el-aside width="200px" class="left">
      <Left @selectMenu="clickMenu"></Left>
    </el-aside>
    <el-container>
      <!--顶部-->
      <el-header height="40px">
        <Head :parentMenu="parentMenu" :curMenu="curMenu" @updateUser="updateUser"
              @toIndex="toIndex"></Head>
      </el-header>
      <!--主要区域-->
      <el-main style="padding-top: 10px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
  <!--用户信息修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      title="用户信息修改"
      width="30%"
      :before-close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="账号" prop="username" class="label-color">
        <el-input v-model="ruleForm.username" disabled></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name" class="label-color">
        <el-input v-model="ruleForm.name" placeholder="请输入姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="原密码" prop="oldPassword" class="label-color">
        <el-input v-model="ruleForm.oldPassword" type="password" placeholder="请输入原密码(留空则不修改)" clearable
                  show-password></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword" class="label-color">
        <el-input v-model="ruleForm.newPassword" type="password" placeholder="请输入新密码(留空则不修改)" clearable
                  show-password></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="againPassword" class="label-color">
        <el-input v-model="ruleForm.againPassword" type="password" placeholder="请输入确认密码(留空则不修改)" clearable
                  show-password></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveUserInfo">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// 首页头部组件
import Head from '../../components/Head.vue'
// 首页左侧组件
import Left from '../../components/Left.vue'
import userApi from '@/api/system/user'

export default {
  name: "AdminIndex",
  components: {
    Head, Left
  },
  data() {
    const validateOldPass = (rule, value, callback) => {
      if (this.ruleForm.newPassword != '' && value === '') {
        callback(new Error('请输入原密码'))
      } else {
        if (this.ruleForm.oldPassword !== '') {
          this.$refs.ruleForm.validateField('newPassword')
        }
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (this.ruleForm.oldPassword != '' && value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.ruleForm.newPassword !== '') {
          this.$refs.ruleForm.validateField('againPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (this.ruleForm.newPassword != '' && value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次新密码不一样"))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        userid: -1,
        username: '',
        name: '',
        oldPassword: '',
        newPassword: '',
        againPassword: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          }
        ],
        oldPassword: [
          {
            validator: validateOldPass,
            trigger: 'blur',
          },
        ],
        newPassword: [
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
        againPassword: [
          {
            validator: validatePass2,
            trigger: 'blur',
          },
        ],
      },
      parentMenu: '',
      curMenu: '',
      dialogVisible: false
    }
  },
  methods: {
    toIndex() {
      this.clickMenu('', '');
    },
    clickMenu(parentName, name) {
      this.parentMenu = parentName;
      this.curMenu = name;
    },
    updateUser() {
      this.dialogVisible = true;
      this.ruleForm.name = this.$store.state.user.name;
      // 从cookie中获取登录用户信息
      // var user = JSON.parse(cookie.getCookie("user"));
      var user = this.$store.state.user;
      this.ruleForm.username = user.username;
      this.ruleForm.userid = user.id;
    },
    saveUserInfo() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 调用保存方法
          userApi.updateUserInfo({
            userId: this.ruleForm.userid,
            name: this.ruleForm.name,
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword,
          }).then((response) => {
            if (response.status == 200) {
              if (response.data.code == 1) {
                this.$message({
                  type: 'success',
                  message: '修改成功',
                });
                this.$store.commit('setUser',response.data.data);// 更新全局user
                this.$refs['ruleForm'].resetFields();// 重置表单输入
                // 关闭窗口
                this.dialogVisible = false;
              } else {
                var msg = response.data.msg;
                this.$message({
                  type: 'warning',
                  message: '修改失败，' + msg,
                });
              }
            } else {
              this.$message({
                type: 'warning',
                message: '请求异常：' + response.status,
              });
            }
          }).catch((error) => {
            this.$message({
              type: 'warning',
              message: '请求异常：' + error,
            });
          })

        }
      })


    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.index {
  height: 100%;
}

.left {
  background-color: #001529;
  color: white;
}

.el-header {
  line-height: 40px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04)
}

</style>