<template>
  <div class="head">
    <div class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/admin/welcome' }" @click="toIndex">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="parentMenu!=''">{{ parentMenu }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="curMenu!=''">{{ curMenu }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
<!--    <span class="position el-icon-location-information">{{ this.$store.state.unitInfo.cemetery }}</span>-->
    <div class="right">
      <el-tooltip
          class="box-item"
          effect="dark"
          content="访问前台"
          placement="bottom"
      >
        <i class="el-icon-s-platform" @click="viewFront"></i>
      </el-tooltip>
      <el-dropdown>
    <span class="el-dropdown-link">
      欢迎您，{{ this.$store.state.user.name }}
      <el-icon class="el-icon-arrow-down"></el-icon>
    </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="updateUser">
              <el-icon class="el-icon-key"></el-icon>
              修改密码
            </el-dropdown-item>
            <el-dropdown-item divided @click="logout">
              <el-icon class="el-icon-back"></el-icon>
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import cookie from "@/utils/cookie";
import userApi from '@/api/system/user'

export default {
  props: {
    // loginName: String,
    parentMenu: String,
    curMenu: String
  },
  methods: {
    logout() {
      this.$messageBox.confirm(
          '确定退出登录吗?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            // 1 清空后台session
            userApi.logout().then(response => {
              if (response.data.code == 1) {
                var msg = response.data.msg;
                this.$message({
                  type: 'success',
                  message: msg,
                });
              } else {
                var msg = response.data.msg;
                this.$message({
                  type: 'warning',
                  message: msg,
                });
              }
            });
            // 2 清空前台数据
            // 2.1 清除cookie
            cookie.delCookie("token");
            cookie.delCookie("user");
            // 2.2 删除状态中的数据
            this.$store.commit('setUser', '');
            this.$store.commit('setToken', '');
            this.$router.push({path: '/admin/login'});
          })
          .catch(() => {
          })
    },
    updateUser() {
      this.$emit('updateUser');
    },
    toIndex() {
      this.$emit('toIndex');
    },
    // 访问前台
    viewFront() {
      window.open('/')
    }
  }
}
</script>

<style scoped>
.head {
  vertical-align: middle;
}

.nav {
  display: inline-block;
  width: calc(50% - 100px);
  vertical-align: middle;
}

.position {
  font-weight: bold;
  color: #409eff;
}

.right {
  display: inline-block;
  float: right;
  /*font-weight: bold;*/
}

.right i {
  color: #409eff;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
